import { log } from '../log/log'

export function xor(...items: any[]) {
  return items.reduce((acc, item) => bit(acc) ^ bit(item), 0)
}

export function bit(val: any) {
  return val ? 1 : 0
}

export function sum(numbers: number[]) {
  let res = 0
  for (const num of numbers) {
    res += num
  }
  return res
}

export function product(numbers: number[]) {
  let res = 1
  for (const num of numbers) {
    res *= num
  }
  return res
}

export function indexByWithFunction<T>(
  items: T[],
  keyFn: (item: T) => string,
  valueFn: (item: T) => any
) {
  const index: Record<string, T> = {}
  items.forEach(i => {
    index[keyFn(i)] = valueFn(i)
  })
  return index
}

export function findIndexOf<T>(items: T[], predicate: (item: T) => boolean) {
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    if (item !== undefined && predicate(item)) {
      return i
    }
  }
  return -1
}

export function firstElement<T>(items: T[] | readonly T[]): T | undefined {
  return items.length > 0 ? items[0] : undefined
}

export function lastElement<T>(items: T[]): T | undefined {
  return items.length > 0 ? items[items.length - 1] : undefined
}

export function partition<T>(items: T[], predicate: (item: T) => boolean): [T[], T[]] {
  const pass = [] as T[]
  const fail = [] as T[]
  items.forEach(item => {
    if (predicate(item)) {
      pass.push(item)
    } else {
      fail.push(item)
    }
  })
  return [pass, fail]
}

/**
 * Returns a map of items indexed by a key. If there are duplicate keys, the last item will be used.
 * If throwIfDuplicate is true, an error will be thrown.
 */
export function indexBy<Item, Key extends string | number>(
  items: Item[],
  keyFn: (item: Item) => Key | null,
  throwIfDuplicate = false
): Partial<Record<Key, Item>> {
  const index: Partial<Record<Key, Item>> = {}
  items.forEach(i => {
    const key = keyFn(i)
    if (key === null) {
      return
    }
    if (throwIfDuplicate && index[key] !== undefined) {
      log.error(
        `duplicate key in indexBy, have: ${JSON.stringify(index[key])}, adding: ${JSON.stringify(
          i
        )}`
      )
      throw new Error(`duplicate key in indexBy: ${key}`)
    }
    index[key] = i
  })
  return index
}

export function multiIndexBy<Item, Key extends string | number>(
  items: Item[] | readonly Item[],
  keyFn: (item: Item) => Key | Key[]
): Partial<Record<Key, Item[]>> {
  const index: Partial<Record<Key, Item[]>> = {}
  items.forEach(i => {
    const k = keyFn(i)
    if (k instanceof Array) {
      for (const _k of k) {
        if (!index[_k]) {
          index[_k] = []
        }
        index[_k]!.push(i)
      }
    } else {
      if (!index[k]) {
        index[k] = []
      }
      index[k]!.push(i)
    }
  })
  return index
}

// find the intersection of all assetIds, this is the asset that is shared by all addresses
// https://bobbyhadz.com/blog/javascript-get-intersection-of-two-sets
export function getIntersection(setA: Set<string>, setB: Set<string>) {
  const intersection = new Set(Array.from(setA).filter(element => setB.has(element)))
  return intersection
}

export function areSetsIdentical<T>(
  a: Set<T> | null | undefined,
  b: Set<T> | null | undefined
): boolean {
  a = a || new Set([])
  b = b || new Set([])
  if (a.size !== b.size) {
    return false
  }
  for (const item of Array.from(a)) {
    if (!b.has(item)) {
      return false
    }
  }
  return true
}

export function lazy<T extends NonNullable<any>>(supplier: () => NonNullable<T>) {
  let val: T | undefined = undefined
  let supplied = false

  return {
    get: (): T => {
      if (!supplied) {
        val = supplier()
        supplied = true
      }
      return val as any
    },
  }
}

export function isNullOrUndefined<T>(value?: T | null): value is null | undefined {
  return value === null || value === undefined
}
