export enum PriceRecommendationChart {
  AvailabilityForecast = 'availability_forecast',
  MarketPosition = 'market_position',
  PriceHistory = 'price_history',
  LeadRate = 'lead_rate',
  RecentDemand = 'recent_demand',
  LeadConversion = 'lead_conversion',
}

export const priceRecommendationChartLabels: { [chart in PriceRecommendationChart]: string } = {
  [PriceRecommendationChart.AvailabilityForecast]: 'Availability forecast',
  [PriceRecommendationChart.MarketPosition]: 'Market position',
  [PriceRecommendationChart.PriceHistory]: 'Price history',
  [PriceRecommendationChart.LeadRate]: 'Lead rate',
  [PriceRecommendationChart.RecentDemand]: 'Recent demand',
  [PriceRecommendationChart.LeadConversion]: 'Lead conversion',
}

export type PriceRecommendationChartToggleMap = {
  [key in PriceRecommendationChart]?: boolean
}
