import { extendTheme } from '@chakra-ui/react'

import * as components from './components'
import * as foundations from './foundations'

export const theme = extendTheme({
  ...foundations,
  components: { ...components },
  colors: {
    brand: {
      50: '#F9F5FF',
      100: '#E3D8FD',
      200: '#CCBCFA',
      300: '#A794F4',
      400: '#8C7AEA',
      500: '#6C5AD5',
      600: '#5746C1',
      700: '#453C9A',
      800: '#38337A',
      900: '#2A2659',
    },
    gray: {
      50: '#F7FAFC',
      100: '#EDF1F7',
      200: '#E2E7F0',
      300: '#CBD3E0',
      400: '#A0ABC0',
      500: '#717D96',
      600: '#4A5368',
      700: '#31394E',
      750: '#292E41',
      800: '#1E2332',
      900: '#1B1C29',
    },
  },
  space: {
    '4.5': '1.125rem',
  },
})

export type Theme = typeof theme
